@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: "knc";
  src: url("/public/knuckle.otf") format("truetype");
}

.text-knc{
  font-family: 'knc';
}